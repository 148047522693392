import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styles from "./404.module.scss"

const NotFound = () => {
  return (
    <Layout>
      <SEO title="404" />
      <h1 className={styles.main}>Page not found</h1>
      <Link to="/">
        <button className={styles.buttonPrimary}>&larr; Back to Home</button>
      </Link>
    </Layout>
  )
}

export default NotFound
